import { useEffect, useState } from 'react'
import { Card, Row, Col, Typography, Divider, Spin, Modal } from 'antd'
import WebForm from './WebForm'
import FormReview from './FormReview'
import Acceptance from './Acceptance'

import { initialValues } from './assets/initialValues'

import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import {
  getAuthError,
  getParams,
  isGantonTemplate,
  navigateToLogin,
  removeOrderErrorAfterSubmit
} from './util'
import useUserRole from './util/useUserRole'
import { getAuth } from '../http'

const { Title, Text } = Typography

const initialFormState = {
  edit: false,
  submitted: false,
  submitting: false,
  posted: false,
  postingFailed: false,
  uploadFailed: false,
  values: initialValues
}

const FormHeader = props => {
  const { formState } = props
  const formId = getParams()

  const newFormText =
    'Please complete the form below to order products with or without alterations.'
  const resubmissionText = 'Please resubmit the form below to order products.'
  let text = !formId ? newFormText : resubmissionText

  if (formState.submitted) {
    text = 'Please review the details below before submitting.'
  }

  return (
    <Row>
      {process.env.REACT_APP_ENV === 'development' && (
        <Col
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            border: '1px solid #FAAD14',
            borderRadius: '8px 0 0 0',
            padding: '6px 24px',
            backgroundColor: '#FAAD14',
            color: 'white'
          }}
        >
          Development
        </Col>
      )}
      <Col span={24} align='center'>
        <Title level={2}>
          {!formId
            ? 'Cambridge Clothing Order Form'
            : 'Cambridge Clothing Resubmission Order Form'}
        </Title>
      </Col>
      {!formState.posted && (
        <>
          <Col span={24} align='center'>
            <Text>{text}</Text>
          </Col>
          <Divider></Divider>
        </>
      )}
    </Row>
  )
}

const FormBody = props => {
  const { formState, setFormState, setUploadProgress } = props
  const id = uuidv4()

  const handleReset = () => {
    getAuthError()
    setFormState(initialFormState)
    window.history.replaceState(null, null, window.location.href.split('?')[0])
  }

  const handlePost = async () => {
    setFormState(state => ({
      ...state,
      submitting: true
    }))

    // NOTE: Check if user is authenticated，if not, redirect to login page
    // didnt use getAuthError() because form will submit，maybe scope different
    // Can comment useAuthError() and getAuth() for local development, uncomment for production
    let authError = null
    await getAuth()
      .then(data => {
        if (
          !data ||
          !data?.clientPrincipal ||
          !data?.clientPrincipal?.userRoles?.includes('authenticated')
        ) {
          authError = 'Session expired, redirect to login page.'
        }
      })
      .catch(error => {
        console.error('Error checking auth status:', error)
        authError = error
      })
    if (authError) {
      console.error(authError)
      navigateToLogin()
      return
    }

    const submitUrl =
      process.env.REACT_APP_ENV === 'development'
        ? `${process.env.REACT_APP_REQUEST_DEV_URL}/api/submit-request`
        : `${process.env.REACT_APP_REQUEST_URL}/api/submit-request`

    const formDataAfterRemoveOrderError = removeOrderErrorAfterSubmit(
      formState.values
    )
    try {
      const submitResponse = await axios.post(
        submitUrl,
        {
          data: formDataAfterRemoveOrderError,
          id: getParams() ? getParams() : id,
          flag: !!getParams() && 'resubmit',
          template: isGantonTemplate(formState?.values?.requestingStore)
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (
        submitResponse?.status !== 200 ||
        submitResponse?.request?.status !== 200
      ) {
        throw new Error(
          'Authentication API request timed out. Please log in again to continue.'
        )
      }

      setFormState(state => ({
        ...state,
        posted: true,
        submitting: false,
        values: formDataAfterRemoveOrderError
      }))
    } catch (e) {
      console.error(e)
      setFormState(state => ({
        ...state,
        posted: true,
        postingFailed: true,
        submitted: true,
        submitting: false,
        values: formState.values
      }))
    }
  }

  const handleEdit = async () => {
    setFormState(state => {
      return {
        ...state,
        edit: true,
        submitted: false
      }
    })
  }

  const handlePostFailed = () => {
    const copiedForm = formState.values

    copiedForm.orders.map(order => (order.alterations = ''))
    const alertMessage = 'Internal server error. Please try again later.'

    const warning = () => {
      Modal.warning({
        title: 'Send Message Failed',
        content: alertMessage
      })
    }

    setFormState(state => ({
      ...state,
      edit: false,
      posted: false,
      postingFailed: false,
      uploadFailed: false,
      submitted: false,
      values: copiedForm
    }))

    warning()
  }

  return !formState.submitted ? (
    <WebForm {...{ formState, setFormState, handleReset }} />
  ) : !formState.posted ? (
    <FormReview
      {...{
        formState,
        setFormState,
        handlePost,
        handleEdit
      }}
    />
  ) : (
    <Acceptance
      {...{
        formState,
        handleReset,
        handlePostFailed
      }}
    ></Acceptance>
  )
}

/**
 * Set this to true to enable test mode, where only users with the test role can log in in prod
 * After prod testing/upgrade, set this to false to allow all users to log in
 * Local/UAT testing: set to false
 */
const isTestPhase = false
// NOTES: When release new feature, set to true in prod to do smoke test before users can access
// const isTestPhase =
//   window.location.origin ===
//   'https://cambridge-clothing-order-form.spacetimeapp.co.nz'

const FormContainer = props => {
  const { userRole, isLoading } = useUserRole()

  const [formState, setFormState] = useState(initialFormState)
  const [uploadProgress, setUploadProgress] = useState([])

  const LoadingTip = () => {
    return (
      <div>
        <div>Submitting...</div>
        <div>{`${
          uploadProgress.filter(x => x.uploadStatus === true).length
        } of ${
          formState.values.orders.filter(order => order.alterations !== '')
            .length
        } file uploaded`}</div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <Spin size='large' spinning={isLoading}>
        Loading...
      </Spin>
    )
  }

  if (isTestPhase && !userRole.includes('test')) {
    return (
      <div
        style={{
          height: '100vh',
          background: '#071C2C',
          color: 'white'
        }}
      >
        System is upgrading, please try again later!
      </div>
    )
  }

  return (
    <div className='App' style={{ height: '100%' }}>
      <Row
        justify='center'
        align='middle'
        style={{ height: '100%', margin: '2rem 0' }}
      >
        <Col sm={22} md={20} lg={18} xl={12}>
          <Spin
            tip={<LoadingTip></LoadingTip>}
            size='large'
            spinning={formState.submitting}
          >
            <Card>
              <FormHeader formState={formState}></FormHeader>
              <FormBody
                formState={formState}
                setFormState={setFormState}
                setUploadProgress={setUploadProgress}
              ></FormBody>
            </Card>
          </Spin>
        </Col>
      </Row>
    </div>
  )
}

export default FormContainer
