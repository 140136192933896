import { notification } from 'antd'
import { getAuth } from '../../http'

export const getParams = () => {
  return new URLSearchParams(window.location.search).get('resubmission') || ''
}

export const isGantonTemplate = code => {
  const gantonCodes = [
    '1RET824',
    '1RET835',
    '1RET825',
    '1RET819',
    '1RET843',
    '1RET846'
  ]
  return gantonCodes.includes(code)
}

export const isObjectEmpty = obj => {
  return Object.values(obj).every(value => {
    if (typeof value === 'object' && value !== null) {
      return isObjectEmpty(value)
    }
    return (
      value === undefined ||
      value === null ||
      value === '' ||
      value === 0 ||
      value === '0'
    )
  })
}

export const removeOrderErrorAfterSubmit = formData => {
  const orders = formData.orders.map(order => {
    delete order.error
    return order
  })
  return { ...formData, orders }
}

export const navigateToLogin = () => {
  notification.warning({
    message: 'Your session has expired',
    description: 'Redirecting you to the login page...'
  })
  setTimeout(() => {
    window.location.href = '/login'
  }, 1000)
}

export const getAuthError = async () => {
  let authError = null
  await getAuth()
    .then(data => {
      if (
        !data ||
        !data?.clientPrincipal ||
        !data?.clientPrincipal?.userRoles?.includes('authenticated')
      ) {
        authError = 'Session expired, redirect to login page.'
      }
    })
    .catch(error => {
      console.error('Error checking auth status:', error)
      authError = error
    })
  if (authError) {
    console.error(authError)
    navigateToLogin()
    return
  }
}
